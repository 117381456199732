import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, retry, catchError } from 'rxjs/operators';

class Role {
  UID: number = 0;

  ACLSFirstContactIndex: number = -1;
  ACLSLubricantLevel: boolean = false;
  ACLSOverpressure: boolean = false;
  ACLSOperation: boolean = false;
  ACLSPumpPowerSupply: boolean = false;
  ACLSGeniePowerSupply: boolean = false;

  FiltrationFirstContactIndex: number = -1;
  FiltrationGeniePowerSupply: boolean = false;
  FiltrationFilterLife: number = 0;

  OilFirstContactIndex: number = -1;
  OilFineParticles: number = 0;
  OilCoarseParticles: number = 0;
  OilWaterPPM: number = 0;
  OilTemperature: number = 0;  
  OilGeniePowerSupply: boolean = false;

  CFTFirstContactIndex: number = -1;
  CFTFilterLife: number = 0;
  CFTGeniePowerSupply: boolean = false;  
}

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  public role: Role;
  private apiRoleDetail = `${environment.api}/role/detail`;
  private apiRoleUpdate = `${environment.api}/role/update`;

  constructor(
    public http: HttpClient,     
  ) { 
    this.role = {
      UID: 0,

      ACLSFirstContactIndex:  -1,
      ACLSLubricantLevel: false,
      ACLSOverpressure: false,
      ACLSOperation: false,
      ACLSPumpPowerSupply: false,
      ACLSGeniePowerSupply: false,

      FiltrationFirstContactIndex: -1,
      FiltrationGeniePowerSupply: false,
      FiltrationFilterLife: 0,

      OilFirstContactIndex: -1,
      OilFineParticles: 0,
      OilCoarseParticles: 0,
      OilWaterPPM: 0,
      OilTemperature: 0,
      OilGeniePowerSupply:  false,

      CFTFirstContactIndex: -1,
      CFTFilterLife: 0,
      CFTGeniePowerSupply: false
    }

  }

  update() {    
    //var headers = {"Content-Type": "application/json", "Accept":"application/json"};     
    var auth = window.btoa(environment.Username + ':' + environment.Password);
    var headers = {"Authorization": "Basic " + auth};  
    return this.http.post(this.apiRoleUpdate, this.role, {headers: headers}).pipe(map((response: any) => response));
  }
  

  private getByUID(uid): Observable<Role> {       
    //console.log('API URL: '+ this.apiRoleDetail); 
    var auth = window.btoa(environment.Username + ':' + environment.Password);
    var headers = {"Authorization": "Basic " + auth};   
    return this.http.get(`${this.apiRoleDetail}/${uid}`, {headers: headers}).pipe(map((response: any) => response));
  }
  
  subscribeSingle(uid) {
    console.log('About to getByUID Role'); 
    this.getByUID(uid)
    .subscribe(
      role => 
      { 
        this.role = role;
        console.log(role);
        return true;      
      },
      error =>  {   
        console.log('error');
        return false;           
      });
    }
  
}
